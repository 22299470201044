import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import { AnalyticsEvent } from "@evercam/shared/types"

export default {
  name: "OpenSupport",
  data() {
    return {
      isLoadingZohoSupport: false,
    }
  },
  computed: {
    ...mapStores(useAccountStore),
  },
  methods: {
    openSupport() {
      if (this.isLoadingZohoSupport) {
        return
      }
      if (this.isZohoWidgetLoaded()) {
        this.toggleZohoSupport()
      } else {
        this.loadZohoWidget()
      }
    },
    toggleZohoSupport() {
      if (
        window.$zoho.salesiq.floatwindow.visible() &&
        window.$zoho.salesiq.floatwindow.visible() === "show"
      ) {
        window.$zoho.salesiq.floatwindow.visible("hide")
      } else {
        window.$zoho.salesiq.visitor.name(
          `${this.accountStore.firstname} ${this.accountStore.lastname}`
        )
        window.$zoho.salesiq.visitor.email(this.accountStore.email)
        window.$zoho.salesiq.floatwindow.visible("show")
        this.$analytics.saveEvent(AnalyticsEvent.HelpMenuOpenLiveChat)
      }
      this.$setTimeout(() => (this.isLoadingZohoSupport = false), 1000)
    },
    hideLiveChatButton() {
      window.$zoho.salesiq.chatbutton.visible("hide")
      window.$zoho.salesiq.floatbutton.visible("hide")
    },
    loadZohoWidget() {
      if (window.loadZSIQ && typeof window.loadZSIQ === "function") {
        this.isLoadingZohoSupport = true
        window.loadZSIQ({
          onLoad: () => {
            this.toggleZohoSupport()
            window.$zoho.salesiq.floatwindow.open(() => {
              this.hideLiveChatButton()
            })
            window.$zoho.salesiq.floatwindow.minimize(() => {
              window.$zoho.salesiq.floatwindow.visible("hide")
            })
            window.$zoho.salesiq.floatwindow.close(() => {
              this.hideLiveChatButton()
            })
          },
        })
      }
    },
    isZohoWidgetLoaded() {
      return window.$zoho?.salesiq?.floatwindow
    },
  },
}
